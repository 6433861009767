import React from 'react'
// import { useStaticQuery, graphql } from 'gatsby'
import Nav from './nav'

import * as styles from './layout.module.css'

const Layout = ({ location, children }) => {
  /*
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)*/

  return (
    <div className={styles.root1}>
      <div className={styles.root2}>
        <h1 className={styles.header}>Dagmara Nowak</h1>
        <h5 className={styles.sub}>Przejrzyście o podatkach</h5>
        <Nav location={location} />
        {/*<Header siteTitle={data.site.siteMetadata.title} location={location} />*/}
        <main>{children}</main>
      </div>
    </div>
  )
}

export default Layout
