import React from 'react'

import imgLinkedIn from '../images/social/linkedin.svg'
import imgEmail from '../images/social/email.svg'

import styles from './contact.module.css'

const Contact = ({ title, loc }) => {
  return (
    <div className={styles.root}>
      <div className={styles.contact}>Kontakt</div>
      <div className={styles.social}>
        <a href="https://www.linkedin.com/in/dagmara-nowak">
          <div className={styles.icon}>
            <img src={imgLinkedIn} alt="Linkedin" />
          </div>
        </a>
        <a href="mailto:hello@dagmaranowak.xyz">
          <div className={styles.icon}>
            <img src={imgEmail} alt="E-Mail" />
          </div>
        </a>
      </div>
    </div>
  )
}

export default Contact
