import React from 'react'
import { Link } from 'gatsby'

import * as styles from './nav.module.css'

const Nav = ({ location }) => {
  const homeStyle =
    location === '/' ? styles.item + ' ' + styles.active : styles.item
  const blogStyle = location.startsWith('/blog/')
    ? styles.item + ' ' + styles.active
    : styles.item

  return (
    <nav className={styles.nav}>
      <div className={styles.decor} />
      <div className={styles.menu}>
        <Link to="/">
          <span className={homeStyle}>O Mnie</span>
        </Link>
        <Link to="/blog">
          <span className={blogStyle}>Blog</span>
        </Link>
      </div>
      <div className={styles.decor} />
    </nav>
  )
}

export default Nav
